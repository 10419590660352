<template class="border-right">
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <div class="d-flex flex-row">
<!--              календарь-->
              <div class="d-flex flex-row">
                <v-date-picker
                  v-model="filters.dates"
                  label="Дата"
                  multiple
                  outlined
                  hide-details
                  range

                />
                <div>
                  <v-list dense>
                    <v-list-item-group v-model="filters.dateSettings" color="primary">
                      <v-list-item :value="'tomorrow'">
                        <v-list-item-content>
                          <v-list-item-title class="title">Завтра</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item :value="'today'">
                        <v-list-item-content>
                          <v-list-item-title class="title">Сегодня</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item :value="'yesterday'">
                        <v-list-item-content>
                          <v-list-item-title class="title">Вчера</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item :value="'week'">
                        <v-list-item-content>
                          <v-list-item-title class="title">На этой неделе</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item :value="'month'">
                        <v-list-item-content>
                          <v-list-item-title class="title">В этом месяце</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item :value="'previous_month'">
                        <v-list-item-content>
                          <v-list-item-title class="title">В прошлом месяце</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider />
                      <v-list-item :value="'deny'">
                        <v-list-item-avatar><v-icon>{{mdiClose}}</v-icon></v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="title">Без фильтра</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </div>
<!--              основные фильтры-->
              <div class="ml-5">
                <v-row class="align-self-end">
                  <v-col cols="3">
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="filters.channel_id"
                          :items="channels"
                          item-value="id"
                          item-text="name"
                          label="Канал"
                          outlined
                          clearable
                          hide-details
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filters.adChannelCategory"
                          :items="adChannelCategories"
                          item-value="id"
                          item-text="name"
                          label="Категория канала"
                          outlined clearable hide-details
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <ad-channel-selector
                          :value.sync="filters.ad_channel_id"
                          :items="adChannelsAvailable"
                          outlined
                          clearable
                          hide-details
                        ></ad-channel-selector>
                      </v-col>
                    </v-row>

                  </v-col>
                  <v-col cols="3">
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="filters.status"
                          :items="statusesAvailable"
                          item-value="status"
                          item-text="status"
                          label="Статус"
                          outlined
                          clearable
                          hide-details
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filters.adPostCategory"
                          :items="adPostCategories"
                          item-value="id"
                          item-text="name"
                          label="Категория поста"
                          outlined clearable hide-details
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <ad-post-selector
                          v-model="filters.ad_post_id"
                          :items="adPostsAvailable"
                          item-value="adPost.id"
                          item-text="adPost.name"
                          label="Пост"
                          outlined
                          clearable
                          hide-details
                        >
                        </ad-post-selector>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="4">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field v-model="filters.price24min" label="Цена 24ч от" outlined clearable hide-details></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="filters.price24max" label="Цена 24ч до" outlined clearable hide-details></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field v-model="filters.price48min" label="Цена 48ч от" outlined hide-details clearable></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="filters.price48max" label="Цена 48ч до" outlined hide-details clearable></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field v-model="filters.priceWeekMin" label="Цена 7дн от" outlined hide-details clearable></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="filters.priceWeekMax" label="Цена 7дн до" outlined hide-details clearable></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
<!--      таблица-->
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-data-table
              :items="publications"
              :headers="headers"
              :items-per-page="50"
              :loading="allPublications.length === 0"
              :footer-props="{
                'items-per-page-options': [50, 100, 200, 500, 1000]
              }"
              :item-class="getBorder()"
              class="border-right"
              dense
            >
              <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{h.text}}</span>
                  </template>
                  <span>{{h.hint}}</span>
                </v-tooltip>
              </template>
              <template v-slot:body.prepend="{}">
                <tr>
                  <td v-for="(header, i) in headers" :key="i">
                    <span v-if="header.value === 'price'" class="text--primary font-weight-bold">
                      {{totalPrice.toLocaleString()}}
                    </span>
                    <span v-else-if="header.value === 'adChannel.avg_views'" class="text--primary font-weight-bold">
                      {{totalAvgViews.toLocaleString()}}
                    </span>
                    <span v-else-if="header.value === 'views'" class="text--primary font-weight-bold">
                      {{totalViews.toLocaleString()}} ({{(totalViews / totalAvgViews * 100).toFixed(0)}}%)
                    </span>
                    <span v-else-if="header.value === 'stats.channel.subs_alive'" class="text--primary font-weight-bold">
                      {{totalActiveSubscribers.toLocaleString()}} ({{totalAliveSubscribersPercent.toFixed(0)}}%)
                    </span>
                    <span v-else-if="header.value === 'subscriberFirstDay'" class="text--primary font-weight-bold">
                      {{avgSubscriberFirstDay.toFixed(2)}}
                    </span>
                    <span v-else-if="header.value === 'subscriberTwoDays'" class="text--primary font-weight-bold">
                      {{avgSubscriberTwoDays.toFixed(2)}}
                    </span>
                    <span v-else-if="header.value === 'subscriberWeek'" class="text--primary font-weight-bold">
                      {{avgSubscriberWeek.toFixed(2)}}
                    </span>
                    <span v-else>
                      <v-text-field
                        v-if="header.filterRef"
                        v-model="filters[header.filterRef]"
                        :label="header.text"
                      ></v-text-field>
                    </span>

                  </td>
                </tr>
              </template>
              <template v-slot:item.channel_id="{item}">
                <div v-if="item.channel_id">{{ channels.find(x=>x.id===item.channel_id).name }}</div>
                <div v-if="item.bot_id && bots.some(x=>x.id===item.bot_id)">{{bots.find(x=>x.id===item.bot_id).name}}</div>
              </template>
              <template v-slot:item.adPost="{item}">
                {{ item.adPost.name }} <v-btn icon @click="editPost(item.adPost)"><v-icon>{{ mdiPencil }}</v-icon></v-btn>
              </template>
              <template v-slot:item.adChannel="{item}">
                {{ item.adChannel.name }}<v-btn icon @click="editAdChannel(item.adChannel)"><v-icon>{{ mdiPencil }}</v-icon></v-btn>
              </template>
              <template v-slot:item.planned_at="{item}">
                {{ formatDate(item.planned_at) }} {{ formatTime(item.planned_at_time) }}
              </template>
              <template v-slot:item.price="{item}">
                {{ item.price.toLocaleString() }}
              </template>
              <template v-slot:item.adChannel.avg_views="{item}">
                {{ item.adChannel.avg_views.toLocaleString() }}
              </template>
              <template v-slot:item.views="{item}">
                {{ item.views.toLocaleString() }} ({{ (item.views / item.adChannel.avg_views * 100).toFixed(0) }}%)
              </template>
              <template v-slot:item.joinRatio="{item}">
                {{ item.joinRatio }}%
              </template>
              <template v-slot:item.stats.channel.subs_alive="{item}">
                <strong v-if="item.stats && item.stats.channel">{{ item.stats.channel.subs_alive.toLocaleString() }}</strong> ({{ item.subscribersAlivePercent }}%)
              </template>
              <template v-slot:item.subscribersAlivePercent="{item}">
                {{ item.subscribersAlivePercent }}%
              </template>
              <template v-slot:item.status="{item}">
                <view-publication-dialog :publication="item" :color="getStatusColor(item)" :status="item.status"></view-publication-dialog>
<!--                <v-chip small :color="getStatusColor(item)" text-color="white">{{ item.status }}</v-chip>-->
              </template>
              <template v-slot:item.stats.channel.subs_24h="{item}">
                <v-chip v-if="item.stats.channel.subs_alive" class="font-weight-medium" :color="getSubscriberFirstDayColor(item.price / item.stats.channel.subs_24h)" text-color="white">{{ (item.price / item.stats.channel.subs_24h).toFixed(1) }}</v-chip>
              </template>
              <template v-slot:item.stats.channel.subs_48h="{item}">
                <v-chip v-if="item.stats.channel.subs_alive" class="font-weight-medium" :color="getSubscriberTwoDaysColor(item.price / item.stats.channel.subs_48h)" text-color="white">{{ (item.price / item.stats.channel.subs_48h).toFixed(1) }}</v-chip>
              </template>
              <template v-slot:item.stats.channel.subs_1w="{item}">
                <v-chip v-if="item.stats.channel.subs_alive" class="font-weight-medium" :color="getSubscriberWeekColor(item.price / item.stats.channel.subs_1w)" text-color="white">{{ (item.price / item.stats.channel.subs_1w).toFixed(1) }}</v-chip>
              </template>
              <template v-slot:item.cpm="{item}">
                <v-chip v-if="item.cpm" :color="getCPMColor(item.cpm)" text-color="white">{{ item.cpm.toFixed(0) }}</v-chip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="postDialog" width="800px">
      <perfect-scrollbar :options="{suppressScrollX: true}">
        <ad-post v-model="postItem" @close="postDialog=false"></ad-post>
      </perfect-scrollbar>
    </v-dialog>
    <v-dialog v-model="adChannelDialog" width="800px">
      <perfect-scrollbar :options="{suppressScrollX: true}">
        <ad-channel v-model="adChannelItem" @close="adChannelDialog=false"></ad-channel>
      </perfect-scrollbar>
    </v-dialog>
  </v-container>
</template>
<script>
import _ from 'lodash'
import { mdiClose, mdiPencil } from '@mdi/js'
import { defineComponent, ref, computed, watch } from '@vue/composition-api'
import moment from 'moment'
import store from '@/store'
import AdPost from '../posts/AdPost.vue'
import AdChannel from '../channels/AdChannel.vue'
import AdChannelSelector from '@/components/AdChannelSelector'
import AdPostSelector from '@/components/AdPostSelector'
import ViewPublicationDialog from "@/views/ads/publications/ViewPublicationDialog"

export default defineComponent({
  name: 'adPublications',
  components: {AdPost, AdChannel, AdChannelSelector, AdPostSelector, ViewPublicationDialog},
  setup() {
    const headers = ref([
      {text: 'id', value: 'id'},
      {text: 'Канал', value: 'channel_id'},
      {text: 'Пост', value: 'adPost'},
      {text: 'Источник', value: 'adChannel'},
      {text: 'Запланировано', value: 'planned_at'},
      {text: 'Цена', value: 'price', filterRef: 'price', cellClass: 'border-right'},
      {text: 'Статус', value: 'status'},
      {text: 'План. пр.', value: 'adChannel.avg_views', hint: 'Сколько просмотров набирают другие посты из этого источника'},
      {text: 'Просмотры', value: 'views', cellClass: 'border-right'},
      // {text: 'Пришло', value: 'activeSubscribers', hint: 'Количество оставшихся до сих пор подписчиков'},
      {text: 'Пришло', value: 'stats.channel.subs_alive', hint: 'Количество оставшихся до сих пор подписчиков'},

      {text: 'К', value: 'joinRatio', hint: 'Кол-во оставшихся до сих пор подписчиков / просмотры * 100', cellClass: 'border-right'},
      // {text: 'Живых', value: 'subscribersAlivePercent', hint: 'Процент оставшихся подписчиков'},
      // {text: '24ч', value: 'subscriberFirstDay', hint: 'Стоимость подписчика за первый день'},
      {text: '24ч', value: 'stats.channel.subs_24h', hint: 'Стоимость подписчика за первый день'},
      {text: '48ч', value: 'stats.channel.subs_48h', hint: 'Стоимость подписчика за первые 2 дня'},
      // {text: '7 дн', value: 'subscriberWeek', hint: 'Стоимость подписчика за первую неделю', cellClass: 'border-right'},
      {text: '7 дн', value: 'stats.channel.subs_1w', hint: 'Стоимость подписчика за первую неделю', cellClass: 'border-right'},
      {text: 'CPM', value: 'cpm', hint: 'Цена поста / просмотры * 1000'},
    ])
    const postItem = ref({})
    const postDialog = ref(false)
    const adChannelItem = ref({})
    const adChannelDialog = ref(false)

    const filters = ref({
      channel_id: null,
      ad_channel_id: null,
      ad_post_id: null,
      status: null,
      planned_at: null,
      dates: null,
      dateSettings: null,
      adChannelCategory: null,
      adPostCategory: null,
      price24min: null,
      price24max: null,
      price48min: null,
      price48max: null,
      priceWeekMin: null,
      priceWeekMax: null,
    })

    const filterByChannel = ref(null)
    const filterByPost = ref(null)
    const filterByStatus = ref(null)
    const filterByAdChannel = ref(null)
    const filterByDate= ref(
      //current date in yyyy-mm-dd
      null
    )

    const allPublications = computed(()=>store.getters.adPublications)
    const publications = computed(()=>{
      let pubs = allPublications.value
      //refactor filterByX to filters.value.x
      if (filters.value.channel_id) {pubs = pubs.filter(x=>x.channel_id===filters.value.channel_id)}
      if (filters.value.ad_channel_id) {pubs = pubs.filter(x=>x.ad_channel_id===filters.value.ad_channel_id)}
      if (filters.value.ad_post_id) {pubs = pubs.filter(x=>x.ad_post_id===filters.value.ad_post_id)}
      if (filters.value.status) {pubs = pubs.filter(x=>x.status===filters.value.status)}
      if (filters.value.adChannelCategory) {pubs = pubs.filter(x=>x.adChannel.category===filters.value.adChannelCategory)}
      if (filters.value.adPostCategory) {pubs = pubs.filter(x=>x.adPost.category===filters.value.adPostCategory)}
      if (filters.value.price24min) {pubs = pubs.filter(x=>x.stats.channel.subs_24h>=filters.value.price24min)}
      if (filters.value.price24max) {pubs = pubs.filter(x=>x.stats.channel.subs_24h<=filters.value.price24max)}
      if (filters.value.price48min) {pubs = pubs.filter(x=>x.stats.channel.subs_48h>=filters.value.price48min)}
      if (filters.value.price48max) {pubs = pubs.filter(x=>x.stats.channel.subs_48h<=filters.value.price48max)}
      if (filters.value.priceWeekMin) {pubs = pubs.filter(x=>x.stats.channel.subs_1w>=filters.value.priceWeekMin)}
      if (filters.value.priceWeekMax) {pubs = pubs.filter(x=>x.subscriberWeek<=filters.value.priceWeekMax)}
      if (filters.value.dates) {
        if (filters.value.dates.length===1) {
          pubs = pubs.filter(x=>moment(x.planned_at).isSame(filters.value.dates[0], 'day'))
        } else {
          pubs = pubs.filter(x=>moment(x.planned_at).isBetween(filters.value.dates[0], filters.value.dates[1], 'day', '[]'))
        }
      }
      return pubs
    })

    const bots = computed(()=>store.getters.bots)
    const channels = computed(()=>store.state.channels)
    const adChannelsAvailable = computed(()=>_.uniqBy(allPublications.value, x=>x.ad_channel_id))
    const adPostsAvailable = computed(()=>_.uniqBy(allPublications.value, x=>x.ad_post_id))
    const statusesAvailable = computed(()=>_.uniqBy(allPublications.value, x=>x.status))
    const avgCpm = computed(()=>_.meanBy(publications.value, x=>x.cpm))
    const avgSubscriberFirstDay = computed(()=>_.meanBy(publications.value, x=>x.stats.channel.subs_24h))
    const avgSubscriberTwoDays = computed(()=>_.meanBy(publications.value, x=>x.stats.channel.subs_48h))
    const avgSubscriberWeek = computed(()=>_.meanBy(publications.value, x=>x.stats.channel.subs_1w))
    const adChannelCategories = computed(()=>store.getters.adChannelCategories)
    const adPostCategories= computed(()=>store.getters.adPostCategories)
    const totalPrice = computed(()=>_.sumBy(publications.value, x=>x.price))
    const totalAvgViews = computed(()=>_.sumBy(publications.value, x=>x.adChannel.avg_views))
    const totalViews = computed(()=>_.sumBy(publications.value, x=>x.views))
    const totalActiveSubscribers = computed(()=>_.sumBy(publications.value, x=> {
      if (x.stats && x.stats.channel && x.stats.channel.subs_alive) {
        return x.stats.channel.subs_alive
      }
      return 0
    }))
    const totalSubscribers = computed(()=>_.sumBy(publications.value, x=>{
      if (x.stats && x.stats.channel && x.stats.channel.subs_total) {
        return x.stats.channel.subs_total
      }
      return 0
    }))
    const totalAliveSubscribersPercent = computed(()=>totalActiveSubscribers.value/totalSubscribers.value*100)

    const filterByDateSettings = ref(null)

    watch(() => filters.value.dateSettings, value => {
      console.log('watch filterByDateSettings', value)
      let date = false
      if (value === 'month') {
        date = [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
      }else if (value === 'week') {
        // date = [1, 0]
        date = [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')]
      }else if (value === 'today') {
        date = [moment().endOf('day').format('YYYY-MM-DD')]
      }else if (value === 'yesterday') {
        date = [moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD'), moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD')]
      } else if (value === 'tomorrow') {
        date = [moment().add(1, 'days').startOf('day').format('YYYY-MM-DD')]
      } else if (value === 'previous_month') {
        date = [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')]
      } else if (value === 'next_month') {
        date = [moment().add(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().add(1, 'months').endOf('month').format('YYYY-MM-DD')]
      } else if (value === 'deny') {
        date = null
      }
      filters.value.dates = date ? date : null
    })

    const editPost = post =>{
      postItem.value = post
      postDialog.value = true
    }

    const editAdChannel = channel =>{
      adChannelItem.value = channel
      adChannelDialog.value = true
    }

    const formatDate = date => moment(date).format('DD.MM.YY')
    const formatTime = time => moment(time, 'HH:mm:ss').format('HH:mm')

    const getStatusColor = publication =>{
      switch (publication.status) {
        case 'Опубликован':
          //if date in planned_at is past
          return 'success'
        case 'Запланирован':
          return 'info'
        case 'Не вышел':
          return 'error'
        default:
          return ''
      }
    }

    const getCPMColor = cpm => {
      //if cpm is 15% more than avg (avgCpm) return 'success', if is 15% less return 'error', else return 'info'
      if (cpm < avgCpm.value * 0.8) return 'success'
      return cpm > avgCpm.value * 1.2 ? 'error' : 'info'
    }

    const getSubscriberFirstDayColor = subscriberFirstDay => {
      if (subscriberFirstDay < avgSubscriberFirstDay.value * 0.8) return 'success'
      return subscriberFirstDay > avgSubscriberFirstDay.value * 1.2 ? 'error' : 'info'
    }

    const getSubscriberTwoDaysColor = subscriberTwoDays => {
      if (subscriberTwoDays < avgSubscriberTwoDays.value * 0.8) return 'success'
      return subscriberTwoDays > avgSubscriberTwoDays.value * 1.2 ? 'error' : 'info'
    }

    const getSubscriberWeekColor = subscriberWeek => {
      if (subscriberWeek < avgSubscriberWeek.value * 0.8) return 'success'
      return subscriberWeek > avgSubscriberWeek.value * 1.2 ? 'error' : 'info'
    }

    const getBorder = () => 'border-right'

    return {
      avgCpm, avgSubscriberFirstDay, avgSubscriberTwoDays, avgSubscriberWeek,
      totalPrice, totalViews, totalAvgViews, totalActiveSubscribers, totalAliveSubscribersPercent,
      editPost, editAdChannel,
      getStatusColor, getCPMColor, getSubscriberFirstDayColor, getSubscriberTwoDaysColor, getSubscriberWeekColor, getBorder,
      postItem, postDialog,
      adChannelItem, adChannelDialog,
      headers,
      adChannelCategories, adPostCategories,
      publications, allPublications,
      channels, bots,
      adChannelsAvailable, adPostsAvailable, statusesAvailable,
      filterByChannel, filterByPost, filterByStatus, filterByAdChannel,
      filterByDate, filterByDateSettings, filters,
      formatDate, formatTime,
      mdiPencil, mdiClose,
    }
  },
})
</script>


<style scoped>
/*.pub-table .v-table { border: none; border-collapse: collapse !important; }*/
/deep/ tbody td,th {
  /*border: 1px solid #ddd !important;*/
  padding: 0 5px !important;
  text-align: start;
}
.v-data-table td.border-right {
  border-right: 1px solid #ccc;
}
/deep/ .border-right {
  border-right: 1px solid #ddd !important;
}
/*!*table td:first-child { border-left: none; }*!*/
/*.v-data-table>.v-data-table__wrapper > table > tbody > tr > td {*/
/*  padding: 0 40px;*/
/*}*/
.ps {
  height: 80%;
}
</style>
