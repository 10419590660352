<template>
  <v-dialog v-model="dialog" width="1200px">
    <template v-slot:activator="{ on }">
      <v-chip small :color="color" dark v-on="on">{{status}}</v-chip>
    </template>
    <view-publication :publication="publication" @close="close"></view-publication>
  </v-dialog>
</template>

<script>
import ViewPublication from "@/views/ads/publications/ViewPublication";
import {ref, watch} from "@vue/composition-api"
import store from "@/store"
import {useVModel} from "@vueuse/core";

export default {
  name: "ViewPublicationDialog",
  components: {
    ViewPublication
  },
  props:{
    publication:{
      type: Object,
      required: true
    },
    show:{
      type: Boolean,
      default: false
    },
    status:{
      type: String,
      default: 'Хз [status]'
    },
    color:{
      type: String,
      default: ''
    }
  },
  setup() {
    const dialog = ref(false)
    // watch(dialog, (value) => {
    //   if(!value){
    //     emit('close')
    //   }
    // })
    const close = e =>{
      dialog.value = false
      // console.log('e:', e)
    }
    return {
      dialog,
      close
    }
  }
}
</script>

<style scoped>

</style>
