<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-date-picker v-model="postedAt"></v-date-picker>
          <v-text-field v-model="postedAtTime" label="Время" v-mask="'##:##:##'" placeholder="__:__:__" outlined></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="pub.status"
            :items="statuses"
            outlined
            label="Статус"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="pub.price" label="Цена" outlined></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="pub.views" label="Охват" outlined></v-text-field>
        </v-col>

      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        :loading="saveLoading"
        @click="save()"
      >
        Сохранить
      </v-btn>

      <v-btn
        color="info"
        :loading="resendingInfo"
        @click="resendInfo()"
      >
        Запостить
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        :loading="delLoading"
        @click="del()"
      >
        <v-icon>{{mdiTrashCan}}</v-icon>Удалить
      </v-btn>
      <v-btn
        color="info"
        @click="cancel()"
      >
        Закрыть
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { computed, ref } from "@vue/composition-api"
import store from "@/store";
import httpClient from "@/services/http.service";
import {mdiTrashCan} from "@mdi/js"

export default {
  name: "ViewPublication",
  props:{
    publication:{
      type: Object,
      required: true
    }
  },
  setup(props, {emit}) {
    const saveLoading = ref(false)
    const resendingInfo = ref(false)
    const delLoading = ref(false)
    const pubTemp = ref(props.publication)
    const channels = computed(()=>store.state.channels)
    //returns mix of getters.publicationStatuses and array
    const statuses = computed(()=>store.getters.adPublicationStatuses)
    const pub = computed({
      get: () => pubTemp.value,
      set: (value) => {
        pubTemp.value = value
        emit('update:publication', value)
      }
    })

    const postedAtTime = computed({
      get: () => {
        return pub.value.posted_at.split(' ').length > 1 ? pub.value.posted_at.split(' ')[1] : '12:00:00'},
      set: (value) => {
        pub.value.posted_at = postedAt.value + ' ' + value
      }
    })

    const postedAt = computed({
      get: () => {
        const date = pub.value.posted_at.split(' ').length > 1 ? pub.value.posted_at.split(' ')[0] : null
        if (date === '0000-00-00') return null
        return date
      },
      set: (value) => {
        if (value === null) value = '0000-00-00'
        pub.value.posted_at = value + ' ' + postedAtTime.value
      }
    })

    const resendInfo = () =>{
      resendingInfo.value = true
      httpClient.post(`ad/publications/send-info?id=${pub.value.id}`).then(({data}) => {
        // store.dispatch('add', {mutation: 'updateAdPublication', payload: data})
        // emit('close')
        resendingInfo.value = false
      })
    }

    const save = () =>{
      saveLoading.value = true
      httpClient.put(`ad/publications/${pub.value.id}`, pub.value).then(({data}) => {
        store.dispatch('add', {mutation: 'updateAdPublication', payload: data})
        emit('close')
        saveLoading.value = false
      })
    }

    const del = () =>{
      delLoading.value = true
      httpClient.delete(`ad/publications/delete?id=${pub.value.id}`).then(({data}) => {
        console.log('updateAdPublication:', data)
        store.dispatch('add', {mutation: 'deleteAdPublication', payload: data.id})
        emit('close')
        delLoading.value = false
      })
    }

    const cancel = () =>{
      emit('close')
    }

    return {
      saveLoading, resendingInfo, delLoading,
      postedAt, postedAtTime,
      statuses,
      pub, channels,
      save, cancel, resendInfo, del,
      mdiTrashCan,
    }
  }
}
</script>

<style scoped>

</style>
